import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/media/logo.png";
import { FaLocationArrow } from "react-icons/fa";
import CitySelectionModal from "../../modal/CitySelection";
function BasicExample() {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");

  const handleOpenModal = () => {
    window.location.href = `tel:+918779692292`;
  };

  const handleSearch = () => {
    navigate(`/search/${searchTerm}`);
    window.location.reload();
  };
  
  const [selectedLocation, setSelectedLocation] = useState(localStorage.getItem("location") || "");

  const handleChange = (event) => {
    localStorage.setItem("location",event.target.value);
    window.location.reload();
  };

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary ct-nav">
        <Container>
          <Navbar.Brand href="/">
            <img src={Logo} alt="easyhaibro" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <div className="search-bar">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder={`Search... ${searchTerm}`}
                />
                <button onClick={handleSearch}>Search</button>
              </div>
            </Nav>

            <Nav className="nav-auth">
              <NavDropdown.Item id="operation">
                <FaLocationArrow />{" "}
                  <select
                    value={selectedLocation}
                    className="header-city-select"
                    onChange={(e)=>handleChange(e)}
                  >
                    <option value={""}>Select location</option>
                    <option value={"Delhi"}>Delhi</option>
                    <option value={"Gurugram"}>Gurugram</option>
                    <option value={"Pune"}>Pune</option>
                    <option value={"Bangalore"}>Bangalore</option>
                    <option value={"Mumbai"}>Mumbai</option>
                  </select>
                    <CitySelectionModal />
              </NavDropdown.Item>

              <>
                <button onClick={handleOpenModal} id="login">
                  Contact Sales
                </button>
              </>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default BasicExample;
