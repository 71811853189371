import React from "react";
import { Container, Row, Col, Card, ListGroup, Button } from "react-bootstrap";

const DogContent = () => {
  return (
    <Container fluid className="py-4" style={{ backgroundColor: "#f8f9fa" }}>
      <Row className="mb-4">
        <Col>
          <h2 className="text-center display-4 fw-bold">
            Why Buy Dogs Online in India?
          </h2>
          <p className="fs-5 text-muted text-center">
            The popularity of online pet marketplaces has transformed the way
            Indians bring home their furry friends. With platforms like
            GoodFurs, people across India can explore a wide variety of puppy
            breeds, from Golden Retrievers and Shih Tzus to Beagles and Huskies,
            all from the comfort of their homes. Buying a puppy online offers
            convenience, variety, and transparency, as well as the assurance
            that the puppies come with health certifications, vaccinations, and
            even free vet assistance. This guide explores the most popular puppy
            breeds, prices, care needs, and tips on finding the right puppy for
            your home.
          </p>
        </Col>
      </Row>

      {/* Header Section */}
      <Row className="mb-4">
        <Col>
          <h2 className="text-center display-4 fw-bold">
            Why Choose GoodFurs?
          </h2>
          <p className="text-center fs-5 text-muted">
            GoodFurs ensures a smooth and secure puppy-buying experience by
            offering:
          </p>
        </Col>
      </Row>

      {/* Features Section */}
      <Row className="mb-5">
        <Col md={4}>
          <Card className="h-100 shadow-sm">
            <Card.Body>
              <Card.Title>Certified Health and Purebred Documents</Card.Title>
              <Card.Text>
                Each puppy comes with a health certificate, a vaccination
                record, and a purebred certification (if applicable).
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="h-100 shadow-sm">
            <Card.Body>
              <Card.Title>Post-Purchase Vet Assistance</Card.Title>
              <Card.Text>
                Enjoy 3 months of free vet assistance to answer questions on
                health, diet, and puppy care.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="h-100 shadow-sm">
            <Card.Body>
              <Card.Title>EMI Options</Card.Title>
              <Card.Text>
                With flexible payment options, getting the perfect puppy is
                easier on the wallet.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Puppy Pricing Section */}
      <Row className="mb-5">
        <Col>
          <h2 className="text-center fw-bold display-5">
            Puppy Pricing Guide for Popular Breeds in India
          </h2>
          <p className="fs-5 text-center text-muted">
            Puppy prices vary based on breed, location, breeder quality, and
            age.
          </p>
        </Col>
      </Row>
      <Row>
        {[
          {
            breed: "Golden Retriever",
            price: "₹12,000 - ₹60,000",
            description:
              "Family-friendly and known for their gentle nature, Golden Retrievers are excellent companions for families and singles alike. They need regular exercise, a high-protein diet, and consistent grooming.",
          },
          {
            breed: "Shih Tzu",
            price: "₹10,000 - ₹45,000",
            description:
              "Small, affectionate, and ideal for apartment living, Shih Tzus thrive in both large families and single-person households. They require frequent grooming and thrive on short daily walks.",
          },
          {
            breed: "Labrador",
            price: "₹8,000 - ₹35,000",
            description:
              "Labs are known for their friendly nature and intelligence. They’re versatile, making them suitable for families, singles, and first-time owners. Labradors are happiest with regular exercise and enjoy activities like swimming and running.",
          },
          {
            breed: "Husky",
            price: "₹15,000 - ₹60,000",
            description:
              "Huskies are energetic, playful, and well-suited for active owners. Due to their thick coats, they thrive in cooler climates, though they can adapt to warmer areas with proper care.",
          },
          {
            breed: "German Shepherd",
            price: "₹13,000 - ₹70,000",
            description:
              "Known for their loyalty and intelligence, German Shepherds make excellent guard dogs and family pets. They require consistent training and thrive with active, engaged owners.",
          },
        ].map((breed, index) => (
          <Col md={4} key={index} className="mb-4">
            <Card className="h-100 shadow-sm">
              <Card.Body>
                <Card.Title><h3 className="list-h3"><strong>{breed.breed}</strong></h3></Card.Title>
                <Card.Text>
                  <strong>Price Range:</strong> {breed.price}
                </Card.Text>
                <Card.Text>{breed.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Factors Section */}
      <Row className="mb-5">
        <Col>
          <h2 className="display-5 text-center fw-bold">
            Factors Affecting Puppy Prices in India
          </h2>
          <ul variant="flush" className="fs-5 text-muted">
            <li>
              <strong>Breed Rarity:</strong> Some breeds, like Huskies or Saint
              Bernards, are less common and require specific breeding practices,
              making them more expensive.{" "}
            </li>

            <li>
              <strong>Location:</strong> In cities, the cost of breeding and
              caring for puppies is higher, which can influence prices.
            </li>

            <li>
              <strong>Age and Lineage:</strong> Puppies with champion bloodlines
              or unique coat colors may be priced higher due to their
              desirability.
            </li>
          </ul>
        </Col>
      </Row>

      <Row className="mb-5">
        <h2 className="display-5 text-center fw-bold">
          Top 10 Puppy Breeds and Their Prices
        </h2>
        <Col>
          <ListGroup variant="flush" className="fs-5 text-muted">
            <ListGroup.Item>
              <h3 className="list-h3"><strong>1. Golden Retriever</strong>
              <br />
              Price: ₹12,000 - ₹60,000
              <br />
              Traits: Gentle, friendly, adaptable.
              <br />
              Best For: Families, singles, and first-time owners.</h3>
            </ListGroup.Item>
            <ListGroup.Item>
              <h3 className="list-h3"><strong>2. Shih Tzu</strong>
              <br />
              Price: ₹10,000 - ₹45,000
              <br />
              Traits: Small, affectionate, low-shedding.
              <br />
              Best For: Apartment dwellers, families with kids.</h3>
            </ListGroup.Item>
            <ListGroup.Item>
              <h3 className="list-h3"><strong>3. Labrador</strong>
              <br />
              Price: ₹8,000 - ₹35,000
              <br />
              Traits: Loyal, playful, highly trainable.
              <br />
              Best For: Active owners, families, first-time pet owners.</h3>
            </ListGroup.Item>
            <ListGroup.Item>
              <h3 className="list-h3"><strong>4. Husky</strong>
              <br />
              Price: ₹15,000 - ₹60,000
              <br />
              Traits: Energetic, strong-willed, playful.
              <br />
              Best For: Active, experienced pet owners.</h3>
            </ListGroup.Item>
            <ListGroup.Item>
              <h3 className="list-h3"><strong>5. German Shepherd</strong>
              <br />
              Price: ₹13,000 - ₹45,000
              <br />
              Traits: Intelligent, protective, loyal.
              <br />
              Best For: Families looking for guard dogs, experienced owners.</h3>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col>
          <ListGroup variant="flush" className="fs-5 text-muted">
            <ListGroup.Item>
              <h3 className="list-h3"><strong>6. Beagle</strong>
              <br />
              Price: ₹10,000 - ₹25,000
              <br />
              Traits: Curious, friendly, good with kids.
              <br />
              Best For: Families, singles, active households.</h3>
            </ListGroup.Item>
            <ListGroup.Item>
              <h3 className="list-h3"><strong>7. Pomeranian</strong>
              <br />
              Price: ₹5,000 - ₹25,000
              <br />
              Traits: Alert, lively, great for small spaces.
              <br />
              Best For: Apartment dwellers, single pet owners.</h3>
            </ListGroup.Item>
            <ListGroup.Item>
              <h3 className="list-h3"><strong>8. Pug</strong>
              <br />
              Price: ₹10,000 - ₹25,000
              <br />
              Traits: Friendly, adaptable, low-energy.
              <br />
              Best For: Families with kids, apartment owners.</h3>
            </ListGroup.Item>
            <ListGroup.Item>
              <h3 className="list-h3"><strong>9. Rottweiler</strong>
              <br />
              Price: ₹18,000 - ₹40,000
              <br />
              Traits: Loyal, protective, confident.
              <br />
              Best For: Experienced owners, guard duties.</h3>
            </ListGroup.Item>
            <ListGroup.Item>
              <h3 className="list-h3"><strong>10. Dachshund</strong>
              <br />
              Price: ₹7,000 - ₹20,000
              <br />
              Traits: Playful, alert, good for small homes.
              <br />
              Best For: Singles, families with older children.</h3>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <h2 className="text-center fw-bold display-5">
            How to Choose the Right Puppy Breed for Your Lifestyle
          </h2>
          <ListGroup numbered variant="flush" className="fs-5 text-muted">
            <ListGroup.Item>
              <strong>Living Space:</strong> Small breeds like Pugs and
              Dachshunds adapt well to apartments, while larger breeds need more
              room.
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Activity Level:</strong> Active breeds like Labs and
              Beagles require daily exercise, while Pugs and Shih Tzus are more
              suited for lower activity levels.
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Experience Level:</strong> First-time owners may prefer
              easy-going breeds like Labradors, while experienced owners can
              handle energetic breeds like Huskies.
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Family Dynamics:</strong> Families with kids may benefit
              from gentle breeds like Golden Retrievers or Beagles.
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>

      {/* Puppy Care Section */}
      <Row className="mb-5">
        <Col>
          <h2 className="text-center fw-bold display-5">
            Puppy Care Tips for New Owners
          </h2>
          <ul className="fs-5">
            <li>
              <strong>Feeding: </strong>Establish a feeding schedule with
              age-appropriate food. Consult with a vet for dietary needs.
            </li>
            <li>
              <strong>Grooming:</strong> Regular grooming keeps your puppy’s
              coat healthy. Breeds like Shih Tzus require more frequent
              grooming.
            </li>
            <li>
              <strong>Training and Socialization:</strong> Start with basic
              obedience training and introduce them to various environments and
              people.
            </li>
            <li>
              <strong>Regular Health Checkups:</strong> Schedule routine vet
              checkups, especially during the first year. GoodFurs offers a
              3-month vet assistance plan for new puppy owners.
            </li>
          </ul>{" "}
        </Col>
      </Row>

      {/* Conclusion Section */}
      <Row className="text-center">
        <Col>
          <h2 className="fw-bold display-5">Conclusion</h2>
          <p className="text-muted fs-5">
            With a vast range of adorable puppies available, GoodFurs is your
            go-to platform for certified, healthy, and affordable puppies.
            Explore our listings, and bring home the perfect companion that
            matches your lifestyle. Whether you're looking for a playful Beagle,
            a loyal German Shepherd, or a gentle Golden Retriever, we are here
            to make your puppy-buying journey seamless and enjoyable.
          </p>
        </Col>
      </Row>

      {/* Internal Linking */}
      <Row className="mt-5 text-center">
  <Col>
    <h2 className="list-h3">Buy Dogs For Sale Online In Other Cities</h2>
    <p className="text-muted">
      <a href="#!">Dogs For Sale In Delhi</a> |{" "}
      <a href="#!">Dogs For Sale In Gurgaon</a> |{" "}
      <a href="#!">Dogs For Sale In Noida</a> |{" "}
      <a href="#!">Dogs For Sale In Mumbai</a> |{" "}
      <a href="#!">Dogs For Sale In Pune</a> |{" "}
      <a href="#!">Dogs For Sale In Ahmedabad</a> |{" "}
      <a href="#!">Dogs For Sale In Bangalore</a> |{" "}
      <a href="#!">Dogs For Sale In Chennai</a> |{" "}
      <a href="#!">Dogs For Sale In Hyderabad</a>
    </p>
  </Col>
</Row>

    </Container>
  );
};

export default DogContent;
