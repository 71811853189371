import React, { createContext, useContext } from 'react';
import axios from 'axios';
import API_ENDPOINT from './Endpoints';

const APIContext = createContext();

export const useAPI = () => useContext(APIContext);
const userToken = localStorage.getItem('userToken');
export const APIProvider = ({ children }) => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_HOST, 
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const getData = async (endpoint) => {
    const url = API_ENDPOINT[endpoint];
    try {
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Failed to fetch data');
    }
  };

  const postData = async (endpoint, data) => {
    const url = API_ENDPOINT[endpoint];
    try {
      const response = await axiosInstance.post(url, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Failed to fetch data');
    }
  };

  const patchData = async (endpoint, data) => {
    const url = API_ENDPOINT[endpoint];
    try {
      const response = await axiosInstance.patch(url, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Failed to fetch data');
    }
  };

  const deleteData = async (endpoint) => {
    const url = API_ENDPOINT[endpoint];
    try {
      const response = await axiosInstance.delete(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || 'Failed to fetch data');
    }
  };

  return (
    <APIContext.Provider value={{ postData, getData, patchData, deleteData }}>
      {children}
    </APIContext.Provider>
  );
};


export const createAdoptionService = async (data) => {
  try {
      const response = await axios.post(API_ENDPOINT['services'], data, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${userToken}`
          }
      });
      return response.data;
  } catch (error) {
      throw error;
  }
};

