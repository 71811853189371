import React from "react";
import Container from "react-bootstrap/Container";

function DeliveryProcess() {
  return (
    <Container>
      <div className="mgTagline">
        <div className="tagLeftPrivacy mobile">
         
          <h2>Shipping & Delivery
          </h2>
          <p>At Good Furs, we understand that adopting a pet is a big decision, and we are committed to ensuring the safe and comfortable delivery of your new furry family member. Our shipping and delivery process is designed with the well-being of your pet in mind.
          </p>
          <h4>Nationwide Shipping
          </h4>
          <p>We offer nationwide delivery of our pets to ensure that no matter where you are located, you can find your perfect companion with ease.
          </p>
          <h4>Delivery Process
          </h4>
          <p>

            <ol>
              <li>Health Check and Vaccination:
              Before any pet is shipped, they undergo a thorough health check by a licensed veterinarian. All our puppies and kittens come with a health certificate and are up-to-date on vaccinations.</li>
              <li>Pet Comfort and Safety:
We prioritize the comfort of the pets during transportation. Pets are shipped in secure, climate-controlled environments with frequent monitoring to ensure they are safe and stress-free.
</li>
             <li>Delivery Time:
Delivery times vary based on the location and availability. Typically, you can expect your new pet to arrive within 3 to 7 business days after completing the purchase.
</li>
           <li>Tracking Your Delivery:
Once your pet is on the way, you’ll receive a tracking number so you can stay updated on the progress of the delivery. Our team will keep in touch with you until your new pet safely arrives at your doorstep.
</li>
            </ol>
          </p>


          <h4>Delivery Options
          </h4>
   <p>
   <ol>
          
          <li>Home Delivery:
          We offer convenient home delivery, ensuring your pet is safely brought directly to your doorstep.</li>
          <li>Pick-Up from Nearest Airport:
For some locations, we may offer the option to pick up your pet from the nearest airport, where our team will be waiting to hand over your pet in person.
</li>
    

   </ol>
    </p>
    <h4>International Shipping

          </h4>

          <p>Currently, we do not offer international shipping. We are working towards expanding our services globally and will keep you updated once international delivery becomes available.
          </p>

          <h4>Delivery Policies


          </h4>
          <p>
   <ol>
          
          <li>We ensure that every pet is delivered in excellent condition, but if you notice any issues upon arrival, please contact us immediately.
          </li>
          <li>Refunds or exchanges due to delivery mishaps or concerns will be handled on a case-by-case basis. Our priority is the health and safety of the pets, and we will work with you to resolve any concerns.

</li>
    

   </ol>
    </p>
    <p>If you have any questions or need more information about our shipping and delivery process, feel free to reach out to our team at  +91 87796-92292


</p>
        </div>
      </div>
    </Container>
  );
}

export default DeliveryProcess;
