import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const FloatingButton = () => {
  const handleButtonClick = () => {
    const phoneNumber = '+919004044078';
    const whatsappLink = `https://wa.me/${phoneNumber}`;

    window.open(whatsappLink, '_blank');
  };

  return (
    <div className="float-btn" onClick={handleButtonClick}>
         <p id="book">Support</p>
     <div className='floating-button'>
      
       <FaWhatsapp />
     </div>
      
    </div>
  );
};

export default FloatingButton;
