import React, { useState } from 'react';
import '../assets/css/App.css';
import { GoArrowLeft, GoArrowRight } from "react-icons/go";

const reviews = [
  {
    name: 'Abhishek Yadav',
    title: 'Software Developer',
    stars: 3,
    text: "I recently bought a Golden Retriever puppy from this website, and I couldn’t be happier. The whole process was smooth, and the team was very responsive to all my questions. My new puppy arrived healthy and happy, and it was clear he had been well-cared for. Highly recommend this site for anyone looking for a new furry friend!",
  },
  {
    name: 'Krishna Mohan',
    title: 'Doctor',
    stars: 4,
    text: "Adopting our kitten was a wonderful experience! The staff helped us find the perfect breed for our family, and we couldn’t be more in love with our little British Shorthair. The delivery was timely, and the kitten came with all necessary vaccinations. The customer service was top-notch!",
  },
  {
    name: 'Kshitiz Dayal',
    title: 'Traveller',
    stars: 5,
    text: "We were a bit nervous about buying a pet online, but this website made it so easy and trustworthy. We adopted a pair of adorable bunnies, and they arrived in excellent condition with all the information we needed to take care of them. It’s clear that they genuinely care about the animals they sell.",
  },
  {
    name: 'Rohit Sharma',
    title: 'Data Scientist',
    stars: 4.5,
    text: "Excellent service and beautiful pets! We bought a Sphynx cat from here, and the entire experience was fantastic. The team kept us updated throughout the process, and our cat is just as described—healthy, playful, and loving. Will definitely recommend this website to others.",
  },
  {
    name: 'Aayush Shrivasatav',
    title: 'Anonymous',
    stars: 4,
    text: "I purchased a Cockatiel from this site, and I’m thrilled with the experience. The bird arrived healthy and well-socialized. The website provided great care instructions and even a starter kit. Their customer service is excellent, answering all my queries promptly.",
  },
  {
    name: 'Amandeep Singh',
    title: 'Anonymous',
    stars: 4,
    text: "Just got my Pomeranian puppy from this site, and I’m in love! The process was seamless, and the puppy arrived with all the necessary paperwork and vaccinations. The team was very professional and supportive throughout the whole journey. Will definitely come back for another pet in the future.",
  },
  {
    name: 'Nitin Shakya',
    title: 'Anonymous',
    stars: 5,
    text: "This website is amazing! We got our little hamster from here, and it was such an easy process. The staff were knowledgeable and helped us pick the perfect pet for our small apartment. The hamster arrived healthy and well-cared-for. We appreciate the great customer service!",
  },
  {
    name: 'Harshit',
    title: 'Anonymous',
    stars: 5,
    text: "Adopting a kitten from this website was a delightful experience. We chose a beautiful Maine Coon, and she arrived healthy, playful, and full of energy. The team was very professional and provided all the necessary information. Highly recommend for anyone looking to add a pet to their family.",
  },
  {
    name: 'Praveen Patel',
    title: 'Anonymous',
    stars: 5,
    text: "I was impressed with the variety of small pets available on this site. We adopted a pair of guinea pigs, and they are adorable! The website provided excellent care guides, and the customer service was exceptional. The guinea pigs were delivered safely and in great health.",
  },
  {
    name: 'Mantasha Khan',
    title: 'Anonymous',
    stars: 5,
    text: "Great experience! We bought a Labradoodle puppy, and the entire process was hassle-free. The website provided detailed information about the breed and what to expect. Our puppy arrived healthy and well-adjusted. The team was very supportive and answered all our questions. Highly recommend this site for anyone looking to find a new pet!",
  },
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transition, setTransition] = useState(false);

  const handlePrev = () => {
    setTransition(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? reviews.length - 1 : prevIndex - 1));
      setTransition(false);
    }, 300);
  };

  const handleNext = () => {
    setTransition(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex === reviews.length - 1 ? 0 : prevIndex + 1));
      setTransition(false);
    }, 300);
  };

  const handleDotClick = (index) => {
    if (index !== currentIndex) {
      setTransition(true);
      setTimeout(() => {
        setCurrentIndex(index);
        setTransition(false);
      }, 300);
    }
  };

  return (
    <div className="testimonial-container">
      <svg width="100%" height="100%" viewBox="0 0 1440 1132" fill="none" xmlns="http://www.w3.org/2000/svg" className="testimonial-bg">
        <path opacity="0.08" d="M-529.653 1040.29C-533.033 1018.86 -517.104 704.979 -399.879 446.282C-294.657 214.392 -54.6552 84.2925 222.541 255.002C519.546 437.959 777.299 458.968 985.901 408.98C1191.78 359.411 1330.08 204.527 1392.53 105.469C1528.06 -108.589 1723.09 27.0114 1650.8 309.866C1616.72 441.437 1530.41 477.343 1381.44 489.329C1225.71 501.857 996.236 468.847 676.754 527.101C368.64 583.515 21.0342 723.496 -175.601 904.491C-384.871 1097.28 -501.439 1227.2 -529.653 1040.29Z" fill="url(#paint0_linear_1955_29)"/>
        <defs>
          <linearGradient id="paint0_linear_1955_29" x1="1544.77" y1="894.392" x2="-473.413" y2="243.38" gradientUnits="userSpaceOnUse">
            <stop stopColor="#5D50C6"/>
            <stop offset="1" stopColor="#F85E9F"/>
          </linearGradient>
        </defs>
      </svg>
      <div className={`testimonial-content ${transition ? 'fade-out' : 'fade-in'}`}>
        <div className="testimonial-header">
          <div className="testimonial-info">
            <span className="testimonial-client">Client</span>
            <h2 className="testimonial-title">Testimonials</h2>
          </div>
        </div>
        <span className="testimonial-name">{reviews[currentIndex].name}</span>
        <span className="testimonial-role"> / {reviews[currentIndex].title}</span>
        <div className="testimonial-stars">
          {'★'.repeat(reviews[currentIndex].stars)}
          {'☆'.repeat(5 - reviews[currentIndex].stars)}
        </div>
        <p className="testimonial-text">{reviews[currentIndex].text}</p>
      </div>
      <button className="testimonial-button prev" onClick={handlePrev}><GoArrowLeft/></button>
      <button className="testimonial-button next" onClick={handleNext}><GoArrowRight/></button>
      <div className="testimonial-dots">
        {reviews.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Testimonials;

