import React, { useState, useEffect } from "react";
import { Container, Table, Row, Col } from "react-bootstrap";
import PetAdoptionComp from "../../../component/Service";
import axios from "axios";
import { ShimmerPostItem } from "react-shimmer-effects";
import FilterComponent from "../../../component/FilterComponent";
import Testimonials from "../../../component/Testimonials";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import DogFAQComponent from "../../../component/DogFAQComponent";
import DogContent from "../../../component/DogContent";

const DogType = () => {
  const [petData, setPetData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [filters, setFilters] = useState({
    location: "",
    breed: "",
    priceRange: "",
    gender: "",
    age: "",
    coatType: "",
    color: "",
    size: "",
    temperament: "",
    healthAssurance: "",
  });

  useEffect(() => {
    // Parse the filters from the URL
    const path = location.pathname.split("+");
    const newFilters = {
      location: path[1] || "",
      breed: path[2] || "",
      color: path[3] || "",
      priceRange: path[4] || "",
      gender: path[5] || "",
      age: path[6] || "",
      coatType: path[7] || "",
      size: path[8] || "",
      temperament: path[9] || "",
      healthAssurance: path[10] || "",
    };
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));

    getData(newFilters);
  }, [location]);

  const metaTags = {
    title:
      "Buy Dogs Online in India: Explore Puppy Prices and Available Breeds",
    description:
      "Dogs & Puppies For Sale Online - Check dogs and puppies for sale in India. We have health certified dogs and puppies in India for sale. Check price & buy the best puppy for sale at Good Furs.",
  };

  const getData = (filters) => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}adoption?temprament=${filters.temperament}&location=${filters.location}&breed=${filters.breed}&gender=${filters.gender}&age=${filters.age}&coatType=${filters.coatType}&color=${filters.color}&healthAssurance=${filters.healthAssurance}&price=${filters.priceRange}&size=${filters.size}&petType=dog`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.status === true) {
          setPetData(response.data.data);
          setLoading(false);
        }
        if (response.data.status === false) {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        setLoading(false);
      });
  };

  const placeholderItems = Array.from({ length: 4 });

  const handleFilter = (newFilters) => {
    const filterPath = Object.values(newFilters).join("+");
    window.location.href = `/buy-dogs-online/+${filterPath}`;
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded((prev) => !prev);
  };

  const fullText = `
    Discover a variety of adorable puppies available for sale across India. From the loyal Labrador and playful Golden Retriever to the charming Pomeranian and majestic Husky, there’s a breed for everyone. Puppy prices in India start as low as ₹5,000, with popular breeds such as Huskies (₹15,000 - ₹60,000), Shih Tzus (₹10,000 - ₹45,000), Golden Retrievers (₹12,000 - ₹60,000), and Pomeranians (₹5,000 - ₹25,000). Browse through our selection to find more details about each breed, including personality traits, care needs, and price ranges. GoodFurs also offers valuable breed-specific care tips to help you prepare for your new companion.
  `;

  const truncatedText = `
    Discover a variety of adorable puppies available for sale across India...
  `;

  return (
    <Container>
      <Helmet>
        <title>{metaTags.title}</title>
        <meta name="description" content={metaTags.description} />
      </Helmet>

      <div className="top Dog-container services service-head" />
      <Row className="mb-4">
        <Col>
          <h1 className="text-center display-4 fw-bold">
            Buy Dogs Online in India: Explore Puppy Prices and Available Breeds
            Good Furs
          </h1>
          <p className="text-center fs-5 text-muted">
            {isExpanded ? fullText : truncatedText}
            <span
              className="text-primary ms-2"
              style={{ cursor: "pointer" }}
              onClick={toggleReadMore}
            >
              {isExpanded ? "Read Less" : "Read More"}
            </span>
          </p>
        </Col>
      </Row>

      {/* Top 5 Popular Puppies Breed Table */}
      {isExpanded&&<Row className="mb-5 justify-content-center">
        <Col md={8} lg={6}>
          <h2 className="text-center fw-bold mb-3">
            Top 5 Popular Puppy Breeds in India
          </h2>
          <Table bordered hover className="text-center table-sm">
            <thead>
              <tr>
                <th style={{ width: "60%" }}>Breed</th>
                <th style={{ width: "40%" }}>Puppy Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <a href="#" className="text-decoration-none">
                    Golden Retriever
                  </a>
                </td>
                <td>₹12,000 - ₹60,000</td>
              </tr>
              <tr>
                <td>
                  <a href="#" className="text-decoration-none">
                    Shih-Tzu
                  </a>
                </td>
                <td>₹10,000 - ₹45,000</td>
              </tr>
              <tr>
                <td>
                  <a href="#" className="text-decoration-none">
                    Labrador
                  </a>
                </td>
                <td>₹8,000 - ₹35,000</td>
              </tr>
              <tr>
                <td>
                  <a href="#" className="text-decoration-none">
                    Husky
                  </a>
                </td>
                <td>₹15,000 - ₹60,000</td>
              </tr>
              <tr>
                <td>
                  <a href="#" className="text-decoration-none">
                    German Shepherd
                  </a>
                </td>
                <td>₹13,000 - ₹70,000</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      }
      
      <div className="justify-content-center">
        <FilterComponent onFilter={handleFilter} />
      </div>

      <Container fluid className="container lg-ds">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="services service-head">
              <h2>Type dog</h2>
              <p>Find Your Buddy!</p>
              <h4 id="hide">Find Your Buddy!</h4>
            </div>
          </Col>
        </Row>
      </Container>

      {!loading && petData.length === 0 && (
        <div className="GoodFurs-row">
          <div className="GoodFurs5"></div>
          <div className="GoodFurs5 GoodFurs-404">
            <h2>404 Pet Not Found 🥺</h2>
            <p>Dog/Cat are not our whole life, but they make our lives whole</p>
          </div>
        </div>
      )}

      <div className="adoption">
        {loading && (
          <div className="category-shimmer">
            {placeholderItems.map((_, index) => (
              <ShimmerPostItem
                key={index}
                card
                title
                cta
                imageType="thumbnail"
                imageWidth={50}
                imageHeight={50}
                contentCenter
              />
            ))}
          </div>
        )}

        {!loading && petData.length > 0 && (
          <>
            {petData.map((pet, index) => (
              <PetAdoptionComp
                key={index}
                petImage={pet.serviceImage}
                priceRange={`${pet.servicePrice}`}
                petName={pet.serviceName}
                address={pet.petBreed}
                gender={pet.petGender}
                dateOfBirth={pet.dateOfBirth}
                btnName="Whatsapp"
                petUserName={pet.petName}
                serviceCity={pet.serviceCity}
                serviceId={pet._id}
                petBreed={pet.petBreed}
              />
            ))}
          </>
        )}
      </div>

      <Testimonials />
      <DogFAQComponent />
      <DogContent />
    </Container>
  );
};

export default DogType;
