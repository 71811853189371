import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import { useCategories, handleService } from '../services/services';
import { useNavigate } from "react-router-dom";

const DogFAQComponent = ({ category, handleService }) => {
  const categories = useCategories();
  const navigate = useNavigate();

  const handleClick = (val) => {
    handleService(val, navigate);
  };

  return (
    <div className="faq">
      <div className="FAQHeader">
        <p>SOME OF THE</p>
        <h2 id="faqsHead">Frequently Asked Questions.</h2>
      </div>
      <Container>
        <div className="faqs">
          <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header><h3 className='accordian-h3'>What is the average cost of a puppy in India?</h3></Accordion.Header>
              <Accordion.Body>
                The cost of a puppy in India varies depending on the breed. Prices can start from as low as ₹5,000 for smaller breeds like Pomeranians and go up to ₹60,000 for premium breeds such as Huskies.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header><h3 className='accordian-h3'>Which puppy breed is the most popular in India?</h3></Accordion.Header>
              <Accordion.Body>
                Golden Retrievers, Labrador Retrievers, Beagles, and Shih Tzus are among the most popular puppy breeds in India, known for their affectionate nature, adaptability, and loyalty.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header><h3 className='accordian-h3'>Are there financing options available for purchasing a puppy?</h3></Accordion.Header>
              <Accordion.Body>
                Yes, we offer EMI options for many breeds, making it easier to bring home a pet without straining your budget. Please inquire about available EMI plans when selecting a puppy.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header><h3 className='accordian-h3'>What should I consider when choosing a puppy breed?</h3></Accordion.Header>
              <Accordion.Body>
                Consider factors such as your living space, family needs, maintenance requirements, and lifestyle. For example, if you live in an apartment, smaller or low-shedding breeds like Shih Tzus or Beagles might be ideal.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header><h3 className='accordian-h3'>Do the puppies come with health certificates?</h3></Accordion.Header>
              <Accordion.Body>
                Yes, all puppies listed on GoodFurs come with a health certificate. We also ensure they are vaccinated and provide three months of free vet assistance to ensure a healthy start for your pet.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header><h3 className='accordian-h3'>How can I know if a puppy is right for my family?</h3></Accordion.Header>
              <Accordion.Body>
                Our Puppy Coaches can guide you in selecting a breed that best fits your lifestyle, living environment, and family needs. You can schedule a consultation to discuss your requirements.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header><h3 className='accordian-h3'>Is it safe to buy a puppy online in India?</h3></Accordion.Header>
              <Accordion.Body>
                At GoodFurs, we prioritize the health and welfare of each puppy. We only connect you with reputable breeders who provide health certifications and ensure quality standards. Additionally, our team is available to answer any concerns throughout the process.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header><h3 className='accordian-h3'>What is the return or exchange policy if the puppy does not adapt well?</h3></Accordion.Header>
              <Accordion.Body>
                While bringing home a pet is a long-term commitment, we understand that sometimes situations change. Contact us to discuss available options for rehoming support, if necessary.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header><h3 className='accordian-h3'>How will my puppy be delivered?</h3></Accordion.Header>
              <Accordion.Body>
                At GoodFurs, we prioritize the safety and comfort of each puppy during the delivery process. Depending on your location, we offer safe, pet-friendly transportation options that ensure a smooth journey for your new companion. Puppies are delivered to your doorstep by experienced handlers who prioritize the puppy’s well-being, providing food, water, and regular check-ins throughout the trip. We keep you updated on delivery status and ensure your puppy arrives happy and healthy.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header><h3 className='accordian-h3'>Do you provide a purebred certificate with the puppy?</h3></Accordion.Header>
              <Accordion.Body>
                Yes, we provide a purebred certificate for all eligible puppies to assure you of their pedigree. Each puppy listed on GoodFurs is carefully evaluated, and those meeting breed standards come with certification that verifies their lineage and breed purity. This ensures that you are bringing home a genuine, purebred companion.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header><h3 className='accordian-h3'>Do you provide any post-purchase support for the puppy?</h3></Accordion.Header>
              <Accordion.Body>
                Yes, we offer comprehensive post-purchase support for your new puppy. We provide three months of free vet assistance, where you can consult with our experienced veterinarians for any health or care-related concerns. Additionally, our team is available to guide you with training tips, nutrition advice, and general care recommendations to ensure your puppy's well-being. We want to make sure both you and your new companion have the best start together.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Container>
    </div>
  );
};

export default DogFAQComponent;
