import React from "react";
import Container from "react-bootstrap/Container";
import Accordion from 'react-bootstrap/Accordion';


function About() {
  return (
    <Container>
      <div className="mgTagline">
        <div className="tagLeftPrivacy mobile">
          <h2>About Us</h2>
          <p>
          Welcome to RAA GoodFurs SERVICES PRIVATE LIMITED, your trusted partner in providing convenient and reliable pet care services right at your doorstep. At RAA GoodFurs, we understand that your pets are more than just animals – they are cherished members of your family. That's why we are dedicated to delivering top-notch pet care services designed to make both you and your furry friends happy.
          </p>
         
    
          <h4>Our Mission:</h4>
        
          <p>
          At RAA GoodFurs, our mission is to make pet care easy and accessible. We strive to enhance the well-being of pets and simplify the lives of their owners by offering a range of personalized and professional services tailored to meet the unique needs of each pet.

          </p>

          <div className="faqs faq-mar-bottom">
          <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header><span>01</span>What services does GoodFurs offer?
              </Accordion.Header>
              <Accordion.Body>
              At GoodFurs, we provide a variety of pet care services, including grooming, wellness check-ups, pet sitting, and product delivery. We ensure the convenience of these services by offering them at your doorstep. Whether you're looking to buy a German Shepherd online or need poodles for sale in Delhi, we offer comprehensive care for all pets.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header><span>02</span> Can I buy puppies online through GoodFurs?</Accordion.Header>
              <Accordion.Body>
              Yes, you can! We offer a range of puppies for sale online, including popular breeds like German Shepherd puppies, Golden Retriever puppies, Boxer puppies, and more. Our platform provides a reliable and secure way to buy dog puppies online.
              </Accordion.Body>
            </Accordion.Item>
            
            <Accordion.Item eventKey="2">
              <Accordion.Header><span>03</span>Do you offer specific breeds of dogs for sale?
              </Accordion.Header>
              <Accordion.Body>
              Yes, we specialise in helping you find the perfect pet. You can find a wide variety of breeds such as Beagle, Doberman, Siberian Husky, Golden Retriever, and more. Our selection includes many breeds with health and well-being guaranteed.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header><span>04</span>What is the price range for puppies and kittens on GoodFurs?
</Accordion.Header>
              <Accordion.Body>
              The price of pets varies based on breed and availability. For example, the German Shepherd puppy price or Poodle puppies price in India depends on factors like lineage and location. We ensure competitive pricing while maintaining the highest standards for the pets we offer.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header><span>05</span>Can I buy pets other than dogs through GoodFurs?
              </Accordion.Header>
              <Accordion.Body>
              Absolutely! In addition to dogs, we also have other pets like Persian kittens for sale. Whether you want a feline companion or a specific breed of dog, we help you find your perfect pet.              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header><span>06</span>How do I ensure the pets I buy are healthy?
              </Accordion.Header>
              <Accordion.Body>
              We prioritise the health and well-being of all pets. Each animal listed on our platform undergoes thorough veterinary check-ups. Whether you're buying a Labrador puppy for sale or a Shih Tzu online, you can rest assured that our pets are well-cared for before they reach your home.

              </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="6">
              <Accordion.Header><span>07</span>Is it safe to buy pets online through GoodFurs?

              </Accordion.Header>
              <Accordion.Body>
              Yes, it is completely safe. We ensure secure transactions and provide all necessary information to help you make an informed decision when you buy pets online. Our professional team is always here to assist you through the process.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      


          <h4>What Sets Us Apart:</h4>
          <p>
            <ol>
              <li>Doorstep Convenience: We bring the pet care experience to your doorstep, saving you time and ensuring your pets feel comfortable in their familiar surroundings.</li>
              <li>Experienced Professionals: Our team consists of passionate and experienced pet care professionals dedicated to providing the highest standard of care for your beloved pets.</li>
             <li>Comprehensive Services: From grooming and wellness check-ups to pet sitting and product delivery, we offer a wide range of services to cater to all aspects of your pet's well-being.</li>
           <li>Personalized Care Plans: We understand that every pet is unique. That's why we work with you to create personalized care plans that address the specific needs and preferences of your pets.
</li>
            </ol>
          </p>


          <h4>Our Commitment to Quality</h4>
       <p>Quality is at the heart of everything we do. We prioritize the health, safety, and happiness of your pets, ensuring that they receive the attention and care they deserve. Our commitment to excellence extends to the products we offer, the professionals we employ, and the overall experience we provide to our customers.</p>
         <h4>Data Security</h4>
        

         <h4>Get in Touch</h4>
         <p>Have questions, special requests, or just want to know more about our services? Our friendly team is here to assist you. Contact us at [insert contact email/phone number], and let us show you how RAA GoodFurs SERVICES PRIVATE LIMITED can make pet care easy for you.</p>


<p>Thank you for choosing RAA GoodFurs – where pet care is not just a service; it's a commitment to the well-being of your cherished companions.

</p>
        </div>
      </div>
    </Container>
  );
}

export default About;
