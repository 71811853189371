import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Image } from 'react-bootstrap';
import bangImg from "../assets/media/bang.webp";
import ggmImg from "../assets/media/ggm.webp";
import puneImg from "../assets/media/pune.webp";
import delImg from "../assets/media/del.webp";
import bomImg from "../assets/media/bom.webp";

const CitySelectionModal = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('location')) {
      setShow(false);
    }
  }, []);

  const cities = [
    { name: 'Delhi', image: delImg },
    { name: 'Gurgaon', image: ggmImg },
    { name: 'Pune', image: puneImg },
    { name: 'Bangalore', image: bangImg },
    { name: 'Mumbai', image: bomImg },
  ];

  const handleClose = () => setShow(false);

  const handleSaveCity = (city) => {
    localStorage.setItem("location", city.name);
    handleClose();
    window.location.reload();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select a City</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {cities.map((city, index) => (
              <Col
                onClick={() => handleSaveCity(city)}
                xs={4}
                className="text-center mb-3"
                style={{ cursor: 'pointer' }}
                key={index}
              >
                <Image
                  src={city.image}
                  thumbnail
                  alt={city.name}
                  style={{ width: '100px', height: '100px' }}
                />
                <div>{city.name}</div>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CitySelectionModal;
