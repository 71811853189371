// ReviewsSection.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactStars from 'react-stars';

const reviewsData = [
  {
    id: 1,
    review: 'Incredible service from GoodFurs! My dog has never looked better thanks to the talented groomers who come right to my doorstep. They were gentle, patient, and truly cared about my pet`s well-being. Highly recommend!',
    stars: 5,
  },
  {
    id: 2,
    review: 'I can`t say enough good things about GoodFurs`s grooming service! From booking to the actual grooming session, everything was seamless. My dog absolutely loves the groomers, and they always leave him looking and smelling fantastic. Definitely a game-changer for busy pet owners like me!',
    stars: 5,
  },
  {
    id: 3,
    review: 'Exceptional convenience combined with top-notch grooming skills from GoodFurs! I appreciate how easy it is to schedule appointments and the fact that I don`t have to leave the comfort of my home. The groomers are professional, friendly, and always go above and beyond to make sure my dog looks his best. A definite must-try',
    stars: 4,
  },
  {
    id: 4,
    review: `I'm beyond impressed with the quality of service provided by GoodFurs's grooming company. They pay attention to every detail and use high-quality products that leave my dog's coat shiny and soft. Plus, the convenience of having them come to my home is unbeatable. I wouldn't trust anyone else with my furry friend's grooming needs!`,
    stars: 5,
  },
  {
    id: 5,
    review: `I'm thrilled with the results every time I book a grooming session for my pet with GoodFurs. The groomers are skilled professionals who handle my dog with care and patience. They always listen to my requests and ensure that my pet looks exactly how I want. I'm a loyal customer for life!`,
    stars: 5,
  },

];


const ReviewsPetGrooming = () => {
    const screenWidth = window.innerWidth;

    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToScroll: 1,
    };
  
    if (screenWidth <= 1200) {
      sliderSettings.slidesToShow = 1;
    } else {
      sliderSettings.slidesToShow = 3;
    }

  return (
    <div className="reviews-section">
      <h2>Our happy pet lovers!</h2>
      <Slider {...sliderSettings}>
        {reviewsData.map(review => (
          <div key={review.id} className="review-item">
           
            <ReactStars
            className='review'
              count={5}
              size={20}
              color2={'#FE7A27'}
              edit={false}
              value={review.stars}
            />
            <p>{review.review}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ReviewsPetGrooming;
