

const host = 'https://GoodFurs.easyhaibro.com/auth/v1/';

const API_ENDPOINT = {
    
    categories: `${host}category`,
    famous: `${host}famous-services`
  };
  
  export default API_ENDPOINT;