import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import PetAdoptionComp from "../../../component/Service";
import axios from 'axios';
import { ShimmerPostItem } from "react-shimmer-effects";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FilterComponent from "../../../component/FilterComponent";
import Testimonials from "../../../component/Testimonials";
import FAQComponent from "../../../component/FAQComponent";
import { useLocation, useNavigate } from "react-router-dom";

const DogType = () => {
  const [petData, setPetData] = useState([]);
  const [loading,setLoading]  = useState(false);
  const [filteredData, setFilteredData] = useState([]); 
  const location = useLocation();
  const [filters, setFilters] = useState({
    location: "",
    breed: "",
    priceRange: "",
    gender: "",
    age: "",
    coatType: "",
    color: "",
    size: "",
    temperament: "",
    healthAssurance: ""
  });
  
  useEffect(() => {
    // Parse the filters from the URL
    const path = location.pathname.split("+");
    const newFilters = {
      location: path[1] || "",
      breed: path[2] || "",
      color: path[3] || "",
      priceRange: path[4] || "",
      gender: path[5] || "",
      age: path[6] || "",
      coatType: path[7] || "",
      size: path[8] || "",
      temperament: path[9] || "",
      healthAssurance: path[10] || "",
    };
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));

    getData(newFilters);
  }, [location]);

  


  const getData = (filters) => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}adoption?temprament=${filters.temperament}&location=${filters.location}&breed=${filters.breed}&gender=${filters.gender}&age=${filters.age}&coatType=${filters.coatType}&color=${filters.color}&healthAssurance=${filters.healthAssurance}&price=${filters.priceRange}&size=${filters.size}&petType=cat`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.status === true) {
          setPetData(response.data.data);
          setLoading(false);
        }
        if (response.data.status === false) {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        setLoading(false);
      });
  };

  const placeholderItems = Array.from({ length: 4 });

  const handleFilter = (newFilters) => {
    const filterPath = Object.values(newFilters).join("+");
    window.location.href = `/buy-cats-online/+${filterPath}`;
  };




  return (
    <Container>

    <div className="top"/>
    <div className="top"/>
    <div className="top"/>
    <div className="top"/>
    <FilterComponent onFilter={handleFilter}  />


    <Container fluid className="container lg-ds">
      <Row>
        <Col lg={12} md={12} sm={12}>
           <div className="services service-head">
             <h2>Type Cat</h2>
             <p>Find Your Buddy!</p>
             <h4 id="hide">Find Your Buddy!</h4>
           </div>
         
        </Col>

        
      
      </Row>
    </Container>


    
    {!loading && petData.length === 0 &&
 <>
  <div className="GoodFurs-row">
    <div className="GoodFurs5">
    </div>
    <div className="GoodFurs5 GoodFurs-404">
      <h2>404 Pet Not Found 🥺</h2>
      <p>Dog/Cat are not our whole life, but they make our lives whole</p>
    </div>
  </div>
  </>
}
      <div className="adoption">

        
      {loading &&
      <div className="category-shimmer">

{placeholderItems.map((_, index) => (
        <ShimmerPostItem
          key={index} // Make sure to provide a unique key
          card
          title
          cta
          imageType="thumbnail"
          imageWidth={50}
          imageHeight={50}
          contentCenter
        />
      ))}
  
      </div>
     }
     
     {!loading && petData.length > 0  &&
     <>
      {petData.map((pet, index) => (
          <PetAdoptionComp
            key={index}
            petImage={pet.serviceImage}
            priceRange={`${pet.servicePrice}`}
            petName={pet.serviceName}
            address={pet.petBreed}
            gender={pet.petGender}
            petUserName={pet.petName}
            btnName="Check Price" 
            serviceId={pet._id}
          />
        ))}
     </>
}


      </div>
      {/* <Forum /> */}

      <Testimonials/>
      <FAQComponent/>
    </Container>
  );
}

export default DogType;
